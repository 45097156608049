<template>
	<div class="my-account">
		<div class="header-menu va-row">
			<div class="va-row">
				<div class="flex container-ddh-menu xs12 with-bottom va-row">
					<div class="flex xs9 no-padding">
						<h1>Consultores</h1>
					</div>
					<div class="flex xs3 no-padding text-right cta-menu-area">
						<br /><br />

						<button
							v-on:click="showPeople(emptyPeople, 'add')"
							class="btn btn-change"
						>
							ADICIONAR CONSULTOR
						</button>
					</div>
				</div>
			</div>
			<div class="clearfix"></div>
		</div>

		<div class="container-dhh va-row">
			<div class="flex md12 xs12 no-padding">
				<h4></h4>
			</div>
			<div class="flex md12 xs12 no-padding">
				<div class="ui-grid">
					<div class="my-data-table va-row" id="cards">
						<div class="flex xs12 no-padding fiscal-notes">
							<div class="body-spacing">
								<div class="va-row">
									<div
										class="sets-list table-list-simple"
										v-if="list.length === 0"
									>
										<div class="va-row empty-area">
											<div class="empty-box">
												<div class="empty-box-header">CONSULTOR</div>
												<div class="empty-box-body">
													A lista de consutores estará disponível assim que for
													cadastrado um consultor
												</div>
											</div>
										</div>
									</div>

									<div
										class="sets-list table-list-simple"
										v-if="list.length > 0"
									>
										<div class="va-row header no-padding">
											<div class="flex item">CONSULTOR</div>

											<div class="flex item email">EMAIL</div>

											<div class="flex item area">
												REGIÃO
												<!-- <span
													content="Vencimento do pagamento para liberação das próximas etapas"
													v-tippy="{
														interactive: true,
														arrow: true,
													}"
												>
													&#128712;
												</span> -->
											</div>
											<div class="flex item">
												<!-- <span
													content="Vencimento do pagamento para liberação das próximas etapas"
													v-tippy="{
														interactive: true,
														arrow: true,
													}"
												>
													&#128712;
												</span> -->
											</div>
											<!-- <div class="flex item area">REGIÃO</div>
											<div class="flex item"></div> -->
										</div>

										<div class="empty-box-body">
											<div
												class="va-row lines"
												v-for="(item, id) in list"
												:key="id"
											>
												<div class="va-row" slot="content-line">
													<div class="flex item">
														{{ item.name }}
													</div>
													<div class="flex item email">
														{{ item.email }}
													</div>
													<div class="flex item area">
														{{ item.sector }}
													</div>

													<div class="flex item action">
														<button
															v-on:click.stop.prevent="showPeople(item, 'view')"
															class="btn-change-edit"
														>
															
															<svg width="24" height="24" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path
																	d="M15.9997 21C17.5775 21 18.9164 20.45 20.0164 19.35C21.1164 18.25 21.6664 16.9111 21.6664 15.3333C21.6664 13.7556 21.1164 12.4167 20.0164 11.3167C18.9164 10.2167 17.5775 9.66667 15.9997 9.66667C14.422 9.66667 13.0831 10.2167 11.9831 11.3167C10.8831 12.4167 10.3331 13.7556 10.3331 15.3333C10.3331 16.9111 10.8831 18.25 11.9831 19.35C13.0831 20.45 14.422 21 15.9997 21ZM15.9997 19.0667C14.9553 19.0667 14.072 18.7056 13.3497 17.9833C12.6275 17.2611 12.2664 16.3778 12.2664 15.3333C12.2664 14.2889 12.6275 13.4056 13.3497 12.6833C14.072 11.9611 14.9553 11.6 15.9997 11.6C17.0442 11.6 17.9275 11.9611 18.6497 12.6833C19.372 13.4056 19.7331 14.2889 19.7331 15.3333C19.7331 16.3778 19.372 17.2611 18.6497 17.9833C17.9275 18.7056 17.0442 19.0667 15.9997 19.0667ZM15.9997 25.3333C12.9331 25.3333 10.1386 24.5 7.61641 22.8333C5.09418 21.1667 3.14418 18.9667 1.76641 16.2333C1.69974 16.1222 1.64974 15.9889 1.61641 15.8333C1.58307 15.6778 1.56641 15.5111 1.56641 15.3333C1.56641 15.1556 1.58307 14.9889 1.61641 14.8333C1.64974 14.6778 1.69974 14.5444 1.76641 14.4333C3.14418 11.7 5.09418 9.5 7.61641 7.83333C10.1386 6.16667 12.9331 5.33333 15.9997 5.33333C19.0664 5.33333 21.8609 6.16667 24.3831 7.83333C26.9053 9.5 28.8553 11.7 30.2331 14.4333C30.2997 14.5444 30.3497 14.6778 30.3831 14.8333C30.4164 14.9889 30.4331 15.1556 30.4331 15.3333C30.4331 15.5111 30.4164 15.6778 30.3831 15.8333C30.3497 15.9889 30.2997 16.1222 30.2331 16.2333C28.8553 18.9667 26.9053 21.1667 24.3831 22.8333C21.8609 24.5 19.0664 25.3333 15.9997 25.3333Z"
																	fill="#4965BC" />
															</svg>

														</button>
														<button
															v-on:click.stop.prevent="showPeople(item, 'edit')"
															class="btn-change-edit"
														>
															<svg width="24" height="24" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path
																	d="M5.80646 26.2721C5.34351 25.8039 4.72089 25.5519 4.08314 25.5336L2.75938 28.2091C2.39669 28.9423 3.16607 29.72 3.89087 29.3529L6.53447 28.0139C6.51811 27.38 6.27998 26.7506 5.80646 26.2721Z"
																	fill="#4965BC" />
																<path
																	d="M28.3546 3.62341C27.0932 2.34775 24.8474 2.34775 23.586 3.62341L20.7545 6.5301L25.5233 11.3528L28.3546 8.44595C29.6732 7.11407 29.6732 4.95529 28.3546 3.62341Z"
																	fill="#4965BC" />
																<path
																	d="M19.5556 7.51701L6.40258 20.846L4.87575 23.9319C5.66519 24.0993 6.40966 24.4713 6.99862 25.0666C7.59601 25.6701 7.97291 26.4179 8.13405 27.2039L11.1801 25.6611L11.1884 25.6527L24.3517 12.3674L19.5556 7.51701Z"
																	fill="#4965BC" />
															</svg>

														</button>
														<button
															v-on:click.stop.prevent="
																showPeople(item, 'delete')
															"
															class="btn-change-edit"
														>
															<svg width="24" height="24" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path
																	d="M9.66764 27.9991C9.15672 27.9991 8.70967 27.7991 8.32648 27.3991C7.9433 26.9991 7.7517 26.5325 7.7517 25.9991V6.99913H7.40045C7.1237 6.99913 6.89486 6.90469 6.71391 6.7158C6.53296 6.52691 6.44248 6.28802 6.44248 5.99913C6.44248 5.71024 6.53296 5.47135 6.71391 5.28246C6.89486 5.09358 7.1237 4.99913 7.40045 4.99913H12.4457C12.4457 4.71024 12.5362 4.47135 12.7172 4.28246C12.8981 4.09357 13.127 3.99913 13.4037 3.99913H19.9179C20.1946 3.99913 20.4235 4.09357 20.6044 4.28246C20.7854 4.47135 20.8759 4.71024 20.8759 4.99913H25.9212C26.1979 4.99913 26.4267 5.09358 26.6077 5.28246C26.7886 5.47135 26.8791 5.71024 26.8791 5.99913C26.8791 6.28802 26.7886 6.52691 26.6077 6.7158C26.4267 6.90469 26.1979 6.99913 25.9212 6.99913H25.5699V25.9991C25.5699 26.5325 25.3783 26.9991 24.9951 27.3991C24.6119 27.7991 24.1649 27.9991 23.654 27.9991H9.66764ZM13.0525 22.1325C13.0525 22.4214 13.1429 22.6602 13.3239 22.8491C13.5048 23.038 13.7337 23.1325 14.0104 23.1325C14.2872 23.1325 14.516 23.038 14.697 22.8491C14.8779 22.6602 14.9684 22.4214 14.9684 22.1325V10.8325C14.9684 10.5436 14.8779 10.3047 14.697 10.1158C14.516 9.92691 14.2872 9.83246 14.0104 9.83246C13.7337 9.83246 13.5048 9.92691 13.3239 10.1158C13.1429 10.3047 13.0525 10.5436 13.0525 10.8325V22.1325ZM18.3532 22.1325C18.3532 22.4214 18.4437 22.6602 18.6246 22.8491C18.8056 23.038 19.0344 23.1325 19.3112 23.1325C19.5879 23.1325 19.8168 23.038 19.9977 22.8491C20.1787 22.6602 20.2691 22.4214 20.2691 22.1325V10.8325C20.2691 10.5436 20.1787 10.3047 19.9977 10.1158C19.8168 9.92691 19.5879 9.83246 19.3112 9.83246C19.0344 9.83246 18.8056 9.92691 18.6246 10.1158C18.4437 10.3047 18.3532 10.5436 18.3532 10.8325V22.1325Z"
																	fill="#4965BC" />
															</svg>
														</button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<vuestic-modal
								:show.sync="show"
								v-bind:large="true"
								class="dde-modal"
								ref="largeModal"
								:okText="'modal.confirm' | translate"
								:cancelText="'modal.cancel' | translate"
								:noButtons="true"
							>
								<div slot="title" v-if="peopleModalType === 'view'">
									Visualizar consultor
								</div>
								<div slot="title" v-if="peopleModalType === 'edit'">
									Editar consultor
								</div>
								<div slot="title" v-if="peopleModalType === 'delete'">
									Excluir consultor
								</div>
								<div slot="title" v-if="peopleModalType === 'add'">
									Adicionar consultor
								</div>

								<div v-if="peopleSelected !== null">
									<people-edit
										:people="peopleSelected"
										:typeSend="'post'"
										v-if="peopleModalType === 'add'"
									></people-edit>
									<people-edit
										:people="peopleSelected"
										:typeSend="'put'"
										v-if="peopleModalType === 'edit'"
									></people-edit>

									<people-view-manager
										:people="peopleSelected"
										v-if="peopleModalType === 'view'"
									></people-view-manager>
									<people-delete
										:people="peopleSelected"
										v-if="peopleModalType === 'delete'"
									></people-delete>
								</div>
							</vuestic-modal>
						</div>
					</div>
				</div>
			</div>
		</div>

		<infinite-loading @infinite="getConsultant">
			<div slot="spinner"></div>
			<div slot="no-more"></div>
			<div slot="no-results"></div>
		</infinite-loading>
	</div>
</template>

<script>
	import { mask } from "vue-the-mask";
	import { mapGetters } from "vuex";
	import apiErrors from "services/apiErrors";

	const axios = require("axios");

	export default {
		name: "peoples",
		directives: { mask },
		computed: {
			...mapGetters({
				managerCredential: "managerCredential",
			}),
		},
		mounted() {
			const self = this;

			this.$root.$on("closeModal", () => {
				self.closeModal();
			});

			// CRUD SCHOOLS
			this.$root.$on("addPeople", (people) => {
				//school.grades = []
				self.list.push(people);
			});

			this.$root.$on("editPeople", (people) => {
				var result = self.list.filter((item) => {
					return item.id === people.id;
				});

				var index = self.list.indexOf(result[0]);

				if (index > -1) {
					self.list.splice(index, 1, people);
				}
			});

			this.$root.$on("deletePeople", (people) => {
				var result = self.list.filter((item) => {
					return item.id === people.id;
				});

				var index = self.list.indexOf(result[0]);

				if (index > -1) {
					self.list.splice(index, 1);
				}
			});
		},

		//props: ['address', 'subscription'],

		data() {
			return {
				page: 1,
				list: [],

				emptyPeople: {
					name: "",
					email: "",
					role_id: 20,
					username: "",
					client_id: 1,
					products: null,
					school_id: null,
					document: "",
					sector_id: "",
					external_id: "",

					grade_id: [],
				},

				peopleModalType: null,
				peopleSelected: null,

				files: "",
				show: false,

				regionId: "1",

				sectors_list: [],
			};
		},
		methods: {
			jsonCopy(src) {
				return JSON.parse(JSON.stringify(src));
			},

			getConsultant($state) {
				const self = this;

				let config = {
					headers: {
						Authorization: "Bearer " + this.managerCredential.token,
					},
				};

				axios
					.get(
						process.env.VUE_APP_ROOT_API + "/salesperson?page=" + self.page,
						config
					)
					.then((response) => {
						//self.list = response.data.data.users

						if (response.data.data.users.length) {
							response.data.data.users.forEach((element) => {
								self.list.push(element);
							});
							self.page += 1;
							$state.loaded();
						} else {
							$state.complete();
						}

						self.list = self.list.reverse();
					})
					.catch((e) => {
						// this.errors.push(e)
						apiErrors.handle(e);
					});

				self.list = self.list.reverse();
			},

			showPeople(item, type) {
				const self = this;

				this.peopleModalType = type;

				let config = {
					headers: {
						Authorization: "Bearer " + this.managerCredential.token,
					},
				};

				if (item.id) {
					axios
						.get(
							process.env.VUE_APP_ROOT_API + "/salesperson/" + item.id,
							config
						)
						.then((response) => {
							self.peopleSelected = response.data.data.user;
							self.$refs.largeModal.open();
						})
						.catch((e) => {
							// self.errors.push(e)
							apiErrors.handle(e);
						});
				} else {
					self.peopleSelected = self.jsonCopy(item);
					self.$refs.largeModal.open();
				}
			},

			// onChangeSector (event) {
			//   this.regionSelected = event.target.value
			//   this.getPeople(this.regionSelected)
			// },

			closeModal() {
				if (this.$refs.largeModal) {
					this.$refs.largeModal.close();
				}

				if (this.$refs.gradeModal) {
					this.$refs.gradeModal.close();
				}

				this.peopleSelected = null;
				this.show = false;
			},

			clickOnFile() {
				this.$refs.files.click();
			},

			submitFiles() {
				let formData = new FormData();

				for (var i = 0; i < this.files.length; i++) {
					let file = this.files[i];
					formData.append("csv", file);
				}

				axios
					.post(process.env.VUE_APP_ROOT_API + "/schools/import", formData, {
						headers: {
							"Content-Type": "multipart/form-data",
							Authorization: "Bearer " + this.managerCredential.token,
						},
					})
					.then(function () {
						alert("Upload feito com sucesso");
					})
					.catch(function (e) {
						apiErrors.handle(e);
						alert("Erro, upload falhou");
					});
			},

			handleFileUpload() {
				this.files = this.$refs.files.files;
				this.submitFiles();
			},

			showSubscriberEditing() {
				this.subscriberEditing = false;
			},

			hideSubscriberEditing() {
				this.subscriberEditing = false;
			},

			scrollParent(slug) {
				this.$router.push({ name: slug });
			},
		},
	};
</script>
<style lang="scss" scoped>
	.btn-login {
		background-color: transparent;
		border: 0px;
		color: #4965bc;
	}
	.hide {
		display: none;
	}
	@media (max-width: 580px) {
		.btn-primary {
			width: 100%;
			margin-bottom: 15px;

			&:last-child {
				margin-bottom: 5px;
			}
		}
	}

	// .fiscal-notes ::v-deep .form-group:last-child {
	// 	margin-bottom: 10px !important;
	// }

	.error-label {
		color: red;
		text-align: left;
		margin-bottom: 10px;
	}
	small {
		font-family: Roboto;
		font-size: 12px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #000000;
		text-indent: 15px;
		margin-top: -5px;
		display: block;
		margin-bottom: 10px;
		// padding-left: 15px;
	}
	h5 {
		font-family: "Nunito";
		font-size: 20px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.17;
		letter-spacing: 1.3px;
		color: #000000;
	}
	.address-info {
		margin-bottom: 15px;
	}
	.editing {
		margin-top: 15px;
	}
	.radio-button-wrapper {
		padding: 14px 11px;
		margin-top: 10px;

		@media (max-width: 990px) {
			padding: 20px 19px;
			margin-top: 10px;
		}
	}
	.header-menu-club {
		h1 {
			font-family: "Nunito";
			font-size: 40px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 0.7;
			letter-spacing: normal;
			color: #68596e;
			margin-top: 34px;
			margin-bottom: 34px;

			@media (max-width: 990px) {
				font-size: 18px;
			}
		}
	}
	.add-new-card {
		border-radius: 10px;
		border: dashed 2px #2cd3d8;
		background-color: #ffffff;
		font-family: Nunito;
		font-size: 12px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 3.5;
		letter-spacing: 0.7px;
		text-align: center;
		color: #4965bc;
		margin-bottom: 20px;
		padding: 15px 0px;
		cursor: pointer;

		.plus {
			font-family: "Nunito";
			font-size: 35px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: 0.7px;
			text-align: center;
			color: #ffffff;
			width: 40px;
			height: 40px;
			transform: rotate(-180deg);
			border-radius: 40px;
			border: solid 1px #4965bc;
			background-color: #4965bc;
			display: block;
			margin: 0 auto;
			margin-top: 20px;
			line-height: 36px;
		}
	}

	// Default of page

	h4 {
		font-family: "Nunito";
		font-size: 24px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.17;
		letter-spacing: 1.3px;
		color: #000000;
		margin-bottom: 0;
	}
	.header-menu {
		background-color: white;

		h1 {
			font-family: "Nunito";
			font-size: 40px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 0.7;
			letter-spacing: normal;
			color: #68596e;
			margin-top: 34px;
			margin-bottom: 34px;

			@media (max-width: 990px) {
				font-size: 18px;
			}
		}
	}
	.my-data {
		/* Facebook bar */
		.facebook-tag {
			height: 65px;
			border-radius: 5px;
			background-color: #3a5998;
			color: white;
			padding: 14px 20px;
			margin-bottom: 20px;

			svg {
				font-size: 26px;
				margin-right: 0px;
				float: left;

				path {
					fill: #fff;
				}
			}

			.name {
				display: inline-block;
				margin-top: 1px;
				line-height: 37px;
			}

			.facebook-toggle {
				float: right;
				display: block;
				margin-top: 5px;
			}
		}
	}

	.change-password {
		border-radius: 10px;
		border: solid 2px #f0ebf5;
		background-color: #ffffff;
		padding: 24px 20px;
		position: relative;

		h4 {
			font-size: 16px;
			color: #000000;
			margin-top: 0px;
			margin-bottom: 7px;
			font-family: "Roboto";

			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: normal;
		}

		h5 {
			font-size: 14px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: normal;
			color: #afa2ba;
			margin-top: 0px;
			margin-bottom: 0px;
		}

		.floated-icon {
			float: left;
			width: 36px;
			height: 36px;
			font-size: 30px;

			svg {
				position: relative;
				top: -5px;
				left: -3px;

				path {
					fill: #afa2ba;
				}
			}
		}

		.new-password-group {
			margin-top: 20px;
			display: block;
		}

		.editor {
			right: 18px;
			top: 20%;
		}

		::v-deep .form-group {
			margin-bottom: 20px;
			padding: 22px 20px;

			::v-deep &:last-child {
				margin-bottom: 20px;
			}
		}
	}

	.my-data-table {
		h4 {
			margin-top: 50px;
			margin-bottom: 20px;
		}
	}
	.fiscal-notes {
		border-radius: 10px;
		border: solid 1px #f0ebf5;
		background-color: #ffffff;
		position: relative;
		border: solid 2px #f0ebf5;

		h4 {
			font-size: 16px;
			color: #000000;
			margin-top: 0px;
			margin-bottom: 10px;
			font-family: "Roboto";
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
		}

		.body-spacing {
			padding: 0px;

			@media (max-width: 990px) {
				padding: 25px 15px 15px 15px;
			}

			.btn-change-edit {
				border: none;
				color: #4965bc;
				background: transparent;
				font-size: 20px;
				padding: 0px 2px;
			}

			//  div:last-child{
			//   margin-bottom: 0px;
			// }
		}

		hr {
			border-top: solid 1px #f0ebf5;
			margin-top: 0px;
			margin-bottom: 0px;
		}

		.dual-inputs {
			padding: 0px 8px;
			margin-bottom: 20px;
		}

		.new-password-group {
			margin-top: 20px;
			display: block;
		}

		.editor {
			right: 18px;
			top: 20%;
		}

		.company-code {
			text-transform: capitalize;
		}

		::v-deep .form-group {
			margin-bottom: 20px;
			padding: 22px 20px;

			::v-deep &:last-child {
				margin-bottom: 20px;
			}
		}

		// .panel:last-child{
		//   margin-bottom: 0px;
		// }
	}

	.address-boxes {
		border-radius: 10px;
		border: solid 1px #f0ebf5;
		background-color: #ffffff;
		position: relative;
		border: solid 2px #f0ebf5;

		h4 {
			font-size: 16px;
			color: #000000;
			margin-top: 0px;
			margin-bottom: 10px;
			font-family: "Roboto";
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
		}

		svg {
			path {
				fill: #afa2ba;
			}
		}

		.clube {
			svg {
				path {
					fill: #fff;
				}
			}
		}

		.body-spacing {
			padding: 30px 30px;

			@media (max-width: 990px) {
				padding: 25px 15px 15px 15px;
			}
		}

		hr {
			border-top: solid 2px #f0ebf5;
		}

		.dual-inputs {
			padding: 0px 8px;
			margin-bottom: 20px;
		}

		.new-password-group {
			margin-top: 20px;
			display: block;
		}

		.editor {
			right: 18px;
			top: 20%;
		}

		::v-deep .form-group {
			margin-bottom: 20px;
			padding: 22px 20px;

			::v-deep &:last-child {
				margin-bottom: 20px;
			}
		}

		.more-address {
			border-radius: 10px;
			border: solid 1px #f0ebf5;
			background-color: #ffffff;
		}

		.address-row {
			padding: 16px 0px;
			position: relative;
			border-bottom: solid 1px #f0ebf5;
			b {
				font-size: 16px;
				color: #000000;
			}
			:last-child {
				border-bottom: none;
			}
		}
	}

	.address-row {
		position: relative;
	}

	.more-cards {
		.more-address {
			border-radius: 10px;
			border: solid 1px #f0ebf5;
			background-color: #ffffff;
		}
	}

	.panel {
		// height: 200px;
		border-radius: 10px;
		border: solid 2px #9378f0;
		background-color: #ffffff;
		overflow: hidden;
		margin-bottom: 20px;

		.header-panel {
			padding: 0px 20px;
			background-color: #9378f0;
			line-height: 33px;
			font-size: 16px;
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: normal;
			color: #ffffff;
		}

		.clube img {
			margin-top: -5px;
		}

		b {
			font-size: 16px;
			color: #000000;
		}

		.panel-body {
			position: relative;
			padding: 10px 0px;
			font-family: Roboto;
			font-size: 14px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.5;
			letter-spacing: normal;
			color: #afa2ba;
			margin: 0px !important;

			.flex.xs1 {
				-ms-flex-preferred-size: 8.33333%;
				flex-basis: 6.33333%;
				-webkit-box-flex: 0;
				-ms-flex-positive: 0;
				flex-grow: 0;
				max-width: 6.33333%;
			}

			b {
				font-family: Roboto;
				font-size: 16px;
				font-weight: normal;
				font-style: normal;
				font-stretch: normal;
				line-height: 1.31;
				letter-spacing: normal;
				color: #000000;
			}

			.editor {
				right: 18px;
				top: 20%;
			}
		}
	}

	.subscription-box {
		// padding: 30px !important;

		font-family: Roboto;
		font-size: 16px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.31;
		letter-spacing: normal;
		color: #000000;

		.toggled {
			margin-top: 10px;
		}
	}
	.newsletter {
		// margin-top:40px;
		padding: 31px;
		border: solid 2px #f0ebf5;

		@media (max-width: 990px) {
			margin-top: 0px;
			padding: 15px;
		}
	}

	// Custom Component
	.v-switch-button {
		::v-deep &:before {
			content: "";
			display: block;
			position: relative;
			background-color: #fff;
			width: 6px;
			height: 6px;
			border-radius: 14.5px;
			margin-left: auto;
			margin-right: auto;
			top: 9px;
		}
	}

	.vue-js-switch {
		&.toggled {
			.v-switch-button {
				::v-deep &:before {
					content: "";
					display: block;
					position: relative;
					background-color: #7ed321;
					width: 6px;
					height: 6px;
					border-radius: 14.5px;
					margin-left: auto;
					margin-right: auto;
					top: 9px;
				}
			}
		}
	}

	.name-box {
		@media (max-width: 980px) {
			padding: 0px !important;
		}
	}
	.lastname-box {
		@media (max-width: 980px) {
			padding: 0px !important;
		}
	}

	.empty-area {
		// padding: 33px 31px;

		.empty-box {
			width: 100%;
			border: 1px solid #dfdae4;
			border-radius: 16px;
			overflow: hidden;

			&-header {
				padding: 22px;
				background: #f4f2fb;
				border-bottom: 1px solid #dfdae4;

				font-family: "Roboto";
				font-style: normal;
				font-weight: 700;
				font-size: 12px;
				line-height: 14px;
				/* identical to box height */

				display: flex;
				align-items: center;
				text-transform: uppercase;

				color: #68596e;
			}

			&-body {
				font-family: "Roboto";
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 21px;
				text-align: center;
				color: #68596e;
				padding: 24px;
			}
		}
	}

	.cta-menu-area {
		align-items: center;
		display: flex;
		flex-wrap: wrap-reverse;
	}

	.table-list-simple {
		.item {
			width: 26.65% !important;
			overflow-wrap: anywhere !important;

			&:last-child {
				border-right: none;
				display: block;
				text-align: right;
			}

			&.email {
				width: 40% !important;
			}

			&.area {
				width: 13.35% !important;
			}

			&.action {
				width: 20% !important;
			}
		}

		.content-line {
			.item {
				font-family: "Roboto";
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 21px;
				/* identical to box height, or 150% */
			}
		}
	}
</style>
